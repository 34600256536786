<template>
  <v-container>
    <loading
      :active="loader"
      :is-full-page="fullPage"
      :opacity="0.7"
      color="#ff6b00"
      :height="height"
      :width="width"
    />
    <v-row>
      <v-col cols="12" md="6">
        <label>{{ $t("bankName") }}<span style="color: #f00">*</span></label>
        <v-autocomplete
          :placeholder="$t('bankName')"
          v-model="beneficiaryReqBody.location_name"
          :items="bank_names"
          :error-messages="bankNameErrors"
          @input="$v.beneficiaryReqBody.location_name.$touch()"
          required
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label>
          {{ $t("swift_code_branch") }}<span style="color: #f00">*</span></label
        >
        <v-text-field
          :placeholder="$t('swift_code_placeholder')"
          v-model="beneficiaryReqBody.swift_no"
          :error-messages="swiftCodeErrors"
          @input="$v.beneficiaryReqBody.swift_no.$touch()"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label> {{ $t("branch_code_beneficary") }}</label>
        <v-text-field
          :placeholder="$t('branch_code_beneficary')"
          v-model="beneficiaryReqBody.branch_code"
          :error-messages="ifscCodeErrors"
          @input="$v.beneficiaryReqBody.branch_code.$touch()"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" class="m-0">
        <label
          >{{ $t("account_number_beneficary")
          }}<span style="color: #f00">*</span></label
        >
        <v-text-field
          :placeholder="$t('account_number_beneficary')"
          v-model="beneficiaryReqBody.bank_account_no"
          :error-messages="accountNoErrors"
          @input="$v.beneficiaryReqBody.bank_account_no.$touch()"
          required
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <v-btn
          class="float-left mt-5 px-5 btn-primary-outlined text-capitalize"
          @click="onClickBack"
          outlined
          ><v-icon dark left>mdi-arrow-left</v-icon> {{ $t("backLabel") }}
        </v-btn>
        <v-btn
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          @click="onClickNext"
          outlined
          >{{ $t("next") }} <v-icon dark right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, numeric, alphaNum } from "vuelidate/lib/validators";
import Loading from "vue-loading-overlay";
import commonService from "@/services/CommonService/commonService";
export default {
  name: "AccountSection",
  components: {
    Loading,
  },
  data() {
    return {
      name: "",
      isSubmitted: false,
      fullPage: true,
      height: 100,
      width: 100,
      payment_method: 11,
      bank_names: [],
      payment_location: [],
    };
  },
  props: {
    beneficiaryReqBody: {
      type: Object,
      required: true,
    },
    loader: {
      type: Boolean,
    },
    BusrecieverData: {
      type: Object,
      required: true,
    },
    isTrans: {
      type: String,
      default: "",
    },
    country_code: {
      type: String,
      default: "",
    },
  },
  validations() {
    return {
      beneficiaryReqBody: {
        location_name: {
          required,
        },
        bank_account_no: {
          required,
          numeric,
        },
        branch_code: {
          alphaNum,
        },
        swift_no: {
          required,
        },
      },
    };
  },
  computed: {
    bankNameErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.location_name.$dirty) return errors;
      if (!this.$v.beneficiaryReqBody.location_name.required) {
        errors.push(this.$t("select_bank_name"));
      }
      return errors;
    },
    accountNoErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.bank_account_no.$dirty) return errors;
      !this.$v.beneficiaryReqBody.bank_account_no.required &&
        errors.push(this.$t("account_number"));
      if (!this.$v.beneficiaryReqBody.bank_account_no.numeric) {
        errors.push(this.$t("invalid_account_num"));
      }
      return errors;
    },
    ifscCodeErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.branch_code.$dirty) return errors;
      return errors;
    },
    swiftCodeErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.swift_no.$dirty) return errors;
      !this.$v.beneficiaryReqBody.swift_no.required &&
        errors.push(this.$t("swift_code_required"));
      return errors;
    },
  },
  watch: {
    BusrecieverData: {
      handler(data) {
        console.log("data", data);
        this.beneficiaryReqBody.branch_code = data.branch_code;
        this.beneficiaryReqBody.location_name = data.location_name;
        this.beneficiaryReqBody.location_id = data.location_id;
        this.beneficiaryReqBody.swift_no = data.swift_no;
        this.beneficiaryReqBody.bank_account_no = data.bank_account_no;
      },
    },
    "beneficiaryReqBody.location_name"(val) {
      if (val) {
        const location = this.payment_location.filter((ele) => ele.txt === val);
        this.beneficiaryReqBody.location_id = location[0].val;
      }
    },
    country_code(val) {
      this.getpayLocation(val);
    },
  },
  methods: {
    onClickNext() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit("onClickNext", this.beneficiaryReqBody);
    },
    async getpayLocation(selected_country) {
      try {
        const responseData = await commonService.getPayLocation(
          selected_country,
          "B"
        );
        if (responseData.status == 200) {
          this.payment_location = responseData.data.data.map((i) => {
            return {
              txt: i.locationName,
              val: i.locationId,
            };
          });
          this.bank_names = await responseData.data.data.map(
            (i) => i.locationName
          );
        } else {
          this.payment_location = [];
          this.bank_names = [];
        }
      } catch (err) {
        console.error(err);
        this.payment_location = [];
        this.bank_names = [];
        return;
      }
    },

    onClickBack() {
      this.$emit("onClickBack");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.invalid-feedback-password {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.invalid-feedback-confirm {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.invalid-feedback-swift {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin: auto;
  }
}
</style>
