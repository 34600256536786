<template>
  <section>
    <v-container>
      <loading
        :active="loader"
        :is-full-page="fullPage"
        :opacity="0.7"
        color="#ff6b00"
        :height="height"
        :width="width"
      />
      <v-row justify="center">
        <v-col
          cols="12"
          md="12"
          class="d-flex justify-content-center summary_heading mb-3"
        >
          {{ $t("please_check_the_below_given_information_beneficary") }}
        </v-col>
        <v-expansion-panels accordion v-model="panelOpened">
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <strong> {{ $t("company_information_beneficary") }}</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mx-auto account-main-div">
              <v-row no-gutters>
                <v-col cols="5">
                  {{ $t("businessname_companyname_benificary") }}
                </v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.full_name }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5">
                  {{ $t("company_registration_number_beneficary") }}
                </v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.id_no }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5">
                  {{ $t("nature_of_business_beneficary") }}</v-col
                >
                <v-col cols="7">
                  {{ beneficiaryReqBody.nature_of_business }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("address_beneficary") }}</v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.address }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("relationship") }}</v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.relationship_name }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("country_beneficary") }}</v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.Remittee_country }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("state_beneficary") }} </v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.Remittee_state }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("city_beneficary") }} </v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.Remittee_city }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("postalcode_beneficary") }} </v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.postal_code }}
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <strong>{{ $t("representative_information_beneficary") }}</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mx-auto account-main-div">
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("firstname_beneficary") }}</v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.first_name }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("lastname_beneficary") }}</v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.last_name }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("date_of_birth_benificary") }} </v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.dob }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("designation_beneficary") }} </v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.designation }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("contact_number_beneficary") }} </v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.phone_number }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("email_beneficary") }} </v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.email_id }}
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel> -->

          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <strong>
                {{ $t("account_details_beneficary") }}
              </strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mx-auto account-main-div">
              <v-row no-gutters>
                <v-col cols="5">
                  {{ $t("bank_name_beneficary") }}
                </v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.location_name }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("swift_code_branch") }}</v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.swift_no }}
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="5">
                  {{ $t("branch_code_beneficary") }}
                </v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.branch_code }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5"> {{ $t("bank_account_number") }}</v-col>
                <v-col cols="7">
                  {{ beneficiaryReqBody.bank_account_no }}
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-btn
            class="float-left mt-5 px-5 btn-primary-outlined text-capitalize"
            @click="onClickBack"
            outlined
            ><v-icon dark left>mdi-arrow-left</v-icon>
            {{ $t("backLabel") }}</v-btn
          >
          <v-btn
            class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
            @click="onClickNext()"
            outlined
          >
            <div class="button-loader" v-if="loader">
              <span class="spinner-border spinner-border-sm"></span>
            </div>
            <div v-if="$route.query.Remittee_ID">
              {{ $t("update_beneficary") }}
            </div>
            <div v-else>{{ $t("submit_beneficary") }}</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Loading from "vue-loading-overlay";
export default {
  name: "Summary",
  components: {
    Loading,
  },
  data() {
    return {
      name: "",
      isSubmitted: false,
      fullPage: true,
      height: 100,
      width: 100,
      BussinessUserData: {},
      panelOpened: 0,
    };
  },
  props: {
    beneficiaryReqBody: {
      type: Object,
      default() {
        return {};
      },
    },
    loader: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isTrans: {
      type: String,
      default: "",
    },
  },
  methods: {
    onClickCancel() {
      this.$router.push("/transaction/3");
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    onClickNext() {
      this.$emit("onClickNext", this.beneficiaryReqBody);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";

.summary_heading {
  color: $primary;
}
@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin: auto;
  }
}
</style>
