<template>
  <div class="page-body">
    <TitleBar :title="$t('addBeneficiary')" />
    <v-container>
      <v-alert
        v-if="alertMsg.status"
        :timeout="alertMsg.timeout"
        :color="alertMsg.color"
        :type="alertMsg.type"
        dismissible
      >
        {{ alertMsg.text }}
      </v-alert>
      <v-row class="mt-5 d-flex justify-content-center">
        <v-col col sm="12" md="12" lg="12">
          <v-stepper v-model="currentStep">
            <v-stepper-header>
              <v-stepper-step :complete="currentStep > 1" step="1">
                {{ $t("company_information") }}</v-stepper-step
              >
              <!-- <v-divider></v-divider> -->
              <!-- <v-stepper-step :complete="currentStep > 2" step="2">
                {{
                  $t("representative_information_beneficary")
                }}</v-stepper-step
              > -->
              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 2" step="2">
                {{ $t("account_details_beneficary") }}</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 3" step="3">
                {{ $t("summary_bus") }}</v-stepper-step
              >
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <company-details
                  :isTrans="isTrans"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  :BusrecieverData="BusrecieverData"
                  @onClickNext="onClickNext"
                  @onClickBack="onClickBack"
                  @onCountrySelect="selctedCountry"
                />
              </v-stepper-content>
              <!-- <v-stepper-content step="2">
                <representative-info
                  :isTrans="isTrans"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  :BusrecieverData="BusrecieverData"
                  :bene_country_name="bene_country_name"
                  :loader="loader"
                  @onClickNext="onClickNext"
                  @onClickBack="onClickBack"
                ></representative-info>
              </v-stepper-content> -->
              <v-stepper-content step="2">
                <account-section
                  :isTrans="isTrans"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  :BusrecieverData="BusrecieverData"
                  :loader="loader"
                  :country_code="country_code"
                  @onClickNext="onClickNext"
                  @onClickBack="onClickBack"
                ></account-section>
              </v-stepper-content>
              <v-stepper-content step="3">
                <Summary
                  :isTrans="isTrans"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  @onClickNext="onCreateBeneficiary"
                  @onClickBack="onClickBack"
                ></Summary>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TitleBar from "@/components/navigations/TitleBar.vue";
import CompanyDetails from "@/components/BeneficiaryBus/CompanyDetails/index.vue";
import AccountSection from "@/components/BeneficiaryBus/AccountSection/index.vue";
// import RepresentativeInfo from "@/components/BeneficiaryBus/RepresentativeInfo/index.vue";
import Summary from "@/components/BeneficiaryBus/Summary/index.vue";
import { EventBus } from "../../plugins/events.js";
import remitteService from "@/services/RemitteService/remitteService";
import alerts from "@/mixins/alerts";
export default {
  name: "BeneficiaryBus",
  mixins: [alerts],
  components: {
    TitleBar,
    CompanyDetails,
    AccountSection,
    // RepresentativeInfo,
    Summary,
  },
  data() {
    return {
      steps: [
        { text: this.$t("accountType") },
        { text: this.$t("basicDetails") },
        { text: this.$t("address") },
        { text: this.$t("bankDetails") },
      ],
      isTrans: "",
      currentStep: 1,
      loader: false,
      beneficiaryReqBody: {
        full_name: "",
        id_no: "",
        nature_of_business: "",
        address: "",
        country_code: "",
        Remittee_country: "",
        state_code: "",
        Remittee_state: "",
        city_code: null,
        Remittee_city: "",
        postal_code: "",
        first_name: "",
        last_name: "",
        dob: null,
        designation: "",
        phone_number: "",
        email_id: "",
        payment_code: "B",
        currency_code: "",
        phone_number_cty_code: "",
        relationship_code:"",
        relationship_name:"",
        location_id: "",
        location_name: "",
        bank_name: "",
        branch_code: "",
        swift_no: "",
        bank_account_no: null,
      },
      BusrecieverData: {},
      bene_country_name: "",
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "info",
      },
      country_code: "",
    };
  },
  created() {
    this.$emit("getProfile");
    this.isTrans = this.$route.params?.isTran || "";
    if (this.$route.query.Remittee_ID)
      this.getRemitteDetailByID(this.$route.query.Remittee_ID);
  },
  methods: {
    selctedCountry(val) {
      console.log("selcted country methods :", val);
      this.country_code = val;
    },
    onClickNext() {
      this.currentStep++;
      this.bene_country_name = this.beneficiaryReqBody.phone_number_cty_code;
      console.log("beneficiaryReqBody", this.beneficiaryReqBody);
    },
    onClickBack() {
      this.currentStep--;
    },
    async onCreateBeneficiary() {
      EventBus.$emit("serviceCharge", {
        country_name: sessionStorage.getItem("country_name"),
        paymentmode: "B",
      });

      try {
        this.loader = true;
        let responseData;
        if (this.$route.query.Remittee_ID) {
          responseData = await remitteService.updateBusRemittee(
            Object.assign(this.beneficiaryReqBody, {
              remitteID: this.$route.query.Remittee_ID,
            })
          );
        } else {
          responseData = await remitteService.createBusRemitte(
            this.beneficiaryReqBody
          );
        }
        if (responseData && responseData.data.status_code == 200) {
          this.loader = false;
          this.showAlertSuccess(responseData.data.message);
          if (this.isTrans == "true") {
            this.$router.push("/transaction/3");
          } else {
            setTimeout(() => {
              this.$router.push({ name: "BeneficiarySummary" });
            }, 1500);
          }
        } else {
          this.loader = false;
          this.showAlertError(responseData.data.message);
        }
      } catch (error) {
        this.loader = false;
        this.showAlertError(
          "Error: Something went wrong.Please try again later."
        );
      }
    },
    async getRemitteDetailByID(remitteeID) {
      this.loader = true;
      try {
        const responseData = await await remitteService.getRemitteDetailByID(
          remitteeID
        );
        if (responseData.data.status_code === 200) {
          this.loader = false;
          const remiteeResult = responseData.data.data[0];
          this.BusrecieverData = remiteeResult;
        } else {
          this.loader = false;
        }
      } catch (e) {
        this.loader = false;
        return e;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
::v-deep ul {
  list-style-type: none !important;
}
::v-deep h4 {
  color: $txt;
  text-align: center;
}
.btn-light {
  font-weight: bold;
  color: $dimgrey;
  background: $white;
  margin: 0 5px;
  &:not(:disabled) {
    &:not(.disabled).active {
      color: $secondary;
    }
    &:not(.disabled) {
      &:active {
        color: $secondary;
      }
    }
  }
  &:hover {
    border: 1px solid red !important;
    color: $dimgrey;
    background: $white;
  }
}
.card {
  border: none;
}
::v-deep label {
  color: $lightslategrey !important;
  margin-bottom: 0 !important;
}
::v-deep .btn-light {
  border: 1px solid $lightgrey !important;
}
.floating-placeholder-name {
  color: red;
  margin-top: -12px;
  font-size: 14px;
  text-align: -webkit-left;
}
.color-error {
  background-color: rgb(240, 50, 50);
}
</style>
